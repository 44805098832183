import React from 'react'
import { Link, graphql } from 'gatsby'
import get from 'lodash/get'
import { Helmet } from 'react-helmet'
import styles from './blog.module.css'
import Layout from '../components/layout'
import styled, { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  body {
    background-color: rgb(0, 36, 71);
  }
  nav {
    background-color: #e1eaf2;
  }
`

const Div = styled.div`
  display: block;
  position: relative;
  margin-top: 1rem;
  overflow: hidden;
  color: #111;

  div {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-flow: column wrap;
    -ms-flex-flow: column wrap;
    flex-flow: column wrap;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    z-index: 1;
    height: 100%;
    padding: 1rem;
    background: #e1eaf2;

    /* // background-color: #fff; */
  }

  a {
    display: block;
    width: 100%;
    font-size: 20px;
    font-weight: 700;
    line-height: 1.125;
    word-wrap: break-word;
  }
`

const LayoutCatalog = styled.div`
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: row wrap;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  position: relative;
  width: 100%;
  max-width: 78rem;
  margin: 0 auto;
  background-color: rgb(0, 36, 71);

  .catalog_wrapper {
    display: grid;
    grid-template-columns: 48% 48%;
    grid-template-rows: 70px 0;
    grid-gap: 4%;
    width: 100%;
  }

  .catalog_wrapper > div {
    display: flex;
    justify-content: center;
    flex-direction: column;
    background: #e1eaf2;
    margin-top: 0.67em;
    text-align: center;
  }

  
  .catalog_wrapper > div span {
    font-size: 1.5em;
    margin: 0 auto;
    background: #e1eaf2;
  }

  ul {
    padding: 0;
    list-style-type: none;
  }
`

class CatalogIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const posts = get(this, 'props.data.allContentfulCatalogue.edges')

    return (
      <Layout location={this.props.location}>
        <GlobalStyle />
        <LayoutCatalog>
          <Helmet title="Объекты культурного наследия Старорусского кладбища" />
          <div className="catalog_wrapper">
            <div>
              <span>Исторические справки</span>
            </div>
            <div>
              <span>Учетные карточки объектов</span>
            </div>

            {/* <h2 className="section-headline">Последние записи</h2> */}
            <ul>
              {posts.map(({ node }) => {
                return (
                  <li key={node.slug}>
                    <Div>
                      <div>
                        <Link to={`/catalog/${node.First}/?type=reference`}>{node.First}</Link>
                      </div>
                    </Div>
                  </li>
                )
              })}
            </ul>
            <ul>
              {posts.map(({ node }) => {
                return (
                  <li key={node.slug}>
                    <Div>
                      <div>
                        <Link to={`/catalog/${node.First}/?type=card`}>{node.First}</Link>
                      </div>
                    </Div>
                  </li>
                )
              })}
            </ul>
          </div>
        </LayoutCatalog>
      </Layout>
    )
  }
}

export default CatalogIndex

export const pageQuery = graphql`
  query CatalogIndexQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulCatalogue {
      edges {
        node {
          About {
            About
          }
          First
          People {
            People
          }
          Stories {
            Stories
          }
          Text {
            childMarkdownRemark {
              html
            }
          }
          media {
            fluid(maxWidth: 350, maxHeight: 196, resizingBehavior: SCALE) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  }
`
